<template>
  <div>
    <v-progress-circular v-if="loading" indeterminate color="colorPrimary"></v-progress-circular>

    <v-icon
    v-else
    class="closeButton"
      @click="$emit('onClose')"
      :loading="loading">
      mdi-close

    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'OrganizzeCloseButton',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  directives: {},
  components: {},
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/helpers/_variables.scss';

.closeButton {
    color: $colorPrimaryDarken;
    border: 3px solid $colorPrimaryDarken;
    border-radius: 16px;
    font-size: 16px;
    margin-top: 4px;


    &:hover {
      color: white;
      border: 3px solid white;
    }
  }
</style>